<template>
  <v-app id="customerApp">
    <v-navigation-drawer
      width="280"
      :right="lang == 'ar'"
      app
      v-model="drawer"
      class="bg-secondary"
    >
      <h2 class="my-4 clr-primary text-center">{{ $t("V-shape") }}</h2>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item link to="/customer/home">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("Home") }}</v-list-item-title>
        </v-list-item>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("MySubscriptions") }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link to="/customer/new-subscribe">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon class="mx-2">mdi-plus</v-icon>
                إنشاء اشتراك جديد
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @mouseover="itemIndex = index"
            v-for="(item, index) in mySubscriptions"
            :key="index"
            link
          >
            <v-list-item-content>
              <v-list-item-title class="mx-10">
                {{ item.package_name }} | {{ item.start_date }}

                <div>
                  <ul
                    class="ulSubscribe"
                    v-if="itemIndex == index"
                    style="padding: 5px"
                  >
                   <li
                      @click="goToItems(item.id, item.package_id)"
                      class="item"
                    >
                      استعراض المنيو
                    </li>
                    <li
                      @click="goToRenew(item.id, item.package_id)"
                      class="item"
                    >
                      تجديد الاشتراك
                    </li>
                    <li
                      @click="goToRoute('view-subscribe', item.id)"
                      class="item"
                    >
                      استعراض معلومات الاشتراك
                    </li>
                    <li
                      @click="stop(item.id)"
                      v-if="item.is_ended == 1 "
                      class="item"
                    >
                      إيقاف
                    </li>
                    <li
                      @click="restart(item.id, item)"
                      v-if="item.is_ended == 0 || item.is_ended == 5"
                      class="item"
                    >
                      استئناف
                    </li>
                    <li class="item">تعديل بيانات الاشتراك</li>
                  </ul>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item link to="/customer/wallet">
          <v-list-item-icon>
            <v-icon>mdi-call-split</v-icon>
          </v-list-item-icon>
          <v-list-item-title>محفظتي</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/customer/settings">
          <v-list-item-icon>
            <v-icon>mdi-cogs</v-icon>
          </v-list-item-icon>
          <v-list-item-title>إعدادات</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      class="bg-secondary"
      :style="lang == 'ar' ? 'direction:rtl;' : 'direction:ltr;'"
    >
      <v-row>
        <v-col md="12">
          <div>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <div
              class="d-flex align-center"
              :class="lang == 'ar' ? 'float-left ml-3' : 'float-right mr-3'"
            >
              <v-menu top left dense>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    style="cursor: pointer"
                    @click="changeLang('ar')"
                    width="40"
                    height="40"
                    v-if="lang == 'en'"
                    src="/assets/ar.png"
                    alt=""
                  />
                  <img
                    style="cursor: pointer"
                    @click="changeLang('en')"
                    width="40"
                    height="40"
                    v-else
                    src="/assets/en.png"
                    alt=""
                  />

                  <p v-bind="attrs" v-on="on" class="mx-3 mt-4 clr-primary">
                    {{ user.name }}
                  </p>
                </template>
                <v-list dense>
                  <v-list-item dense>
                    <v-btn small color="danger" @click="logout" text>
                      {{ $t("Logout") }}
                      <v-icon class="my-0 mr-7" dense>mdi-logout</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app></v-footer>

    <!-- Start Dialog Stop -->
    <v-dialog v-model="dialogStop" width="50rem">
      <v-card>
        <v-toolbar color="danger" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("StopSubscribe") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.start_date`),
                  }"
                >
                  <label>{{ $t("StartDate") }}</label>

                  <el-date-picker
                    style="width: 100%"
                    v-model="stopForm.start_date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('StartDate')"
                    class="d-block my-2"
                    name="start_date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.start_date`)"
                >
                  {{ errors.first(`addEditValidation.start_date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                <label>{{ $t("EndDate") }}</label>
                <el-date-picker
                  style="width: 100%"
                  v-model="stopForm.end_date"
                  type="date"
                  class="d-block my-2"
                  name="end_date"
                  hide-details
                  dense
                  outlined
                >
                </el-date-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStop = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogStopConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->

    <!-- Start Dialog Start -->
    <v-dialog v-model="dialogStart" width="50rem">
      <v-card>
        <v-toolbar color="success" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("StartSubscribe") }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-container fluid>
            <v-row class="mt-5">
              <v-col class="py-0" :cols="4">
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.date`),
                  }"
                >
                  <label>{{ $t("Date") }}</label>
                  <el-date-picker
                    style="width: 100%"
                    v-model="startForm.date"
                    type="date"
                    v-validate="'required'"
                    data-vv-scope="addEditValidation"
                    :data-vv-as="$t('Date')"
                    class="d-block my-2"
                    name="date"
                    hide-details
                    dense
                    outlined
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.date`)"
                >
                  {{ errors.first(`addEditValidation.date`) }}
                </div>
              </v-col>

              <v-col class="py-0" :cols="4">
                <label>{{ $t("StopStartDate") }}</label>
                <el-date-picker
                  v-if="startForm && startForm.stop_subscription &&startForm.stop_subscription.start_date"
                  style="width: 100%"
                  :value="startForm.stop_subscription.start_date"
                  type="date"
                  class="d-block my-2"
                  name="date"
                  hide-details
                  dense
                  disabled
                  outlined
                >
                </el-date-picker>
                  <p v-else class="mt-5" style="color:red;">{{$t('NoData')}}</p>
              </v-col>

              <v-col class="py-0" :cols="4">
                <label>{{ $t("StopEndDate") }}</label>
                <el-date-picker
                  v-if="startForm && startForm.stop_subscription &&startForm.stop_subscription.end_date"
                  style="width: 100%"
                  :value="startForm.stop_subscription.end_date"
                  type="date"
                  class="d-block my-2"
                  name="date"
                  hide-details
                  dense
                  disabled
                >
                </el-date-picker>
                <p v-else class="mt-5" style="color:red;">{{$t('NoData')}}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogStart = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="dialogStartConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Dialog Delete -->
  </v-app>
</template>

<script>
import config from "@/config";
import mixin from "@/mixin";
import moment from "moment";
export default {
  name: "App",
  data: () => ({
    itemIndex: 1,
    stopId: null,
    startId: null,
    lang: localStorage.getItem("lang") || "ar",
    drawer: true,
    config: config,
    user: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer"))
      : [],
    mySubscriptions: [],
    dialogStop: false,
    dialogStart: false,
    stopForm: {
      start_date: null,
      end_date: null,
    },
    startForm: {
      date: null,
      stop_subscription: {
        start_date: null,
        end_date: null,
      }
    },
    stopItem: null,
    startItem: null,
  }),
  methods: {
     goToItems(id) {
      this.$router.push({name:'customerItems', params: { id: id}})
    },
    dialogStartConfirm() {
      let sendData = {
        formData: JSON.parse(JSON.stringify(this.startForm)),
      };

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        if (sendData.formData.date)
          sendData.formData.date = moment(sendData.formData.date).format(
            "YYYY-MM-DD"
          );

        sendData.id = this.startId;

        this.$store
          .dispatch(`customers/starCustomerSubscribe`, sendData)
          .then((res) => {
            this.notifySuccess("StartSubscribe", res);
            this.dialogStart = false;
            this.listPackageMenu();
          });
      });
    },
    dialogStopConfirm() {
      let sendData = {
        formData: JSON.parse(JSON.stringify(this.stopForm)),
      };
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        if (sendData.formData.start_date)
          sendData.formData.start_date = moment(
            sendData.formData.start_date
          ).format("YYYY-MM-DD");

        if (sendData.formData.end_date)
          sendData.formData.end_date = moment(
            sendData.formData.end_date
          ).format("YYYY-MM-DD");

        sendData.id = this.stopId;

        this.$store
          .dispatch(`customers/stopCustomerSubscribe`, sendData)
          .then((res) => {
            this.notifySuccess("StopSubscribe", res);
            this.dialogStop = false;
            this.listPackageMenu();
          });
      });
    },
    restart(id, item) {
      this.dialogStart = false;
      this.dialogStop = false;

      this.startId = id;
      
      if(item.stop_subscription) {
        this.startForm.stop_subscription= {
        start_date: item.stop_subscription.start_data,
        end_date: item.stop_subscription.end_data,
      }
      }
      else {
        this.startForm.stop_subscription= {
        start_date: null,
        end_date: null
      }
      }
      
      this.$validator.reset();
      // this.startForm = {
      //   date: null,
      // };
      // this.startForm = {
      //   start_date: "",
      // };
      this.dialogStart = true;
    },
    stop(id) {
       this.dialogStart = false;
      this.dialogStop = false;
      this.stopId = id;
      this.$validator.reset();
      this.stopForm = {
        start_date: "",
        end_date: "",
      };
      this.dialogStop = true;
    },
    goToRenew(id, package_id) {
      localStorage.setItem("package_id_for_renew", package_id);
      this.$router.push({ name: "renew", params: { id: id } });
    },
    goToRoute(route, id) {
      if (id) {
        this.$router.push({ name: route, params: { id: id } });
      } else {
        this.$router.push({ name: route });
      }
    },
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    goTo(page) {
      window.open(page);
    },
    logout() {
      this.$store
        .dispatch("auth/logoutCustomer", this.object)
        .then((result) => {
          this.notifySuccess("Logout", result);
          this.logoutCustomerSuccess();
        })
        .catch((err) => {
          this.logoutCustomerSuccess();
        });
    },
    listPackageMenu() {
      this.$store.dispatch("customers/getMasterListPackageMenu").then((res) => {
        this.mySubscriptions = res.data.resources;
      });
    },
  },
  mounted() {
    try {
      let customer = localStorage.getItem("customer")
        ? JSON.parse(localStorage.getItem("customer"))
        : [];

      this.listPackageMenu();
    } catch (error) {
      //  mixin.methods.logoutSuccess();
    }
  },
};
</script>
<style>
#listSubscribe {
  position: relative;
  top: 130px;
}
ul li {
  list-style: none;
}

.ulSubscribe .item {
  background: #dddff1;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 5px;
  margin: 3px 0;
  font-size: 12px;
}
.ulSubscribe .item:hover {
  background: rgb(156, 255, 255);
}
</style>